

/*=========================
Main
==========================*/
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  padding: .75rem;
  color: #1a1a1a;
  background-color: #f2f2f2;
  max-width: 1140px;
}

.container {
  display: flex;
  flex-direction: row;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*=========================
Aside
==========================*/
aside {
  text-align: left;
  min-width: 150px;
  max-width: 300px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

#sidebar {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 97%;
  position: fixed;
  z-index: 1;
  top: 1rem;
  left: 1rem;
}

/* b/c the sidebar is fixed, need to recreate the div */
#ghost-sidebar{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: unset;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  z-index: -1;
}

h1 {
  text-align: left;
  font-family: 'Fjalla One', sans-serif;
  font-size: 1.8rem;
  margin: .5rem 0;
  color: #000;
  transition: .2s;
}

h1:hover {
  color: #e60073; /* pink */
}

.tagline {
  font-size: .8rem;
  font-style: italic;
  margin-bottom: -6px;
}

ul {
  font-family: 'Fjalla One', sans-serif;
  display: inline-block;
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 1.25rem 0;
}

a {
  color: #333;
  text-decoration: none;
  transition: .3s;
}

a:hover {
  color: #e60073; /* pink */
}

footer {
  position: absolute;
  bottom: 0;
}

.fa-github {
  font-size: 1.25rem;
}


/*=========================
Galleries
==========================*/
h2 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 1.25rem;
  text-transform: lowercase;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  /*flex-direction: column;*/
}

.art-card {
  cursor: pointer;
  text-align: left;
  flex-grow: 1;
  padding: .5rem;
}

.art-titlecard-info {
  font-size: .8rem;
}

img {
  max-width: 100%;
  border: 1px solid #efefef;
}

/*section {
  text-align: left;
}*/

.content {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* npm package masonry */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -1.5rem; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 1.5rem; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column .curated-set-card {
  margin-bottom: 1.5rem;
}

/*=========================
About & cv
==========================*/
.cv-press {
  text-indent: -2em;
  padding-left: 2em;
}

#cv,
#contact,
#about {
  text-align: left;
  font-size: 1rem;
  max-width: 700px;
  scroll-behavior: smooth;
}

#cv p,
#contact p {
  margin-block-start: .1rem;
  margin-block-end: .3rem;
}

h2 {
  display: inline-block;
  height: 1.1rem;
}

h3 {
  font-family: 'Fjalla One', sans-serif;
  font-size: .95rem;
  /* margin-bottom: .25rem; */
}

/*==============================
 Modal
================================*/
.modal-background {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 5vh; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(119, 119, 119, .5);
/* Add Animation - Zoom in the Modal */
  animation-name: zoombig;
  animation-duration: 0.3s;
}

@keyframes zoombig {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

.modal-content-container {
  display: flex;
  position: relative;
  padding: 1rem;
  background: #fff;
  margin: 0 auto;
  max-width: 800px;
  /*border: 1px solid blue;*/
}

#modal-next-button:hover,
#modal-back-button:hover,
.modal-close-button:hover {
  color: #777;
}

/*==============================
 Modal - left container/back button
================================*/
section.modal-left-container {
  display: block;
  width: 3rem;
  /*border: 1px solid purple;*/
}

#modal-back-button {
  cursor: pointer;
  position: relative;
  top: 40%;
  top: 299px;
  color: #aaa;
  transition: .2s;
  margin-right: .75rem;
  /*z-index: 1;*/
}


/*==============================
 Modal - image container
================================*/
section.modal-image-and-info-container {
  display: block;
  max-width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  transition: .3s;
  margin-left: auto;
  margin-right: auto;
}

.modal-image-container {

}

#modal-image {
  width: 100%;
  max-width: 700px;
  display: block;
  margin-bottom: .5rem;
  margin: 0 auto;
}


.modal-info-container {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  text-align: left;
  animation-name: nextimage;
  animation-duration: 1s;
}

.modal-info-container p {
  margin-block-start: .1rem;
  margin-block-end: .1rem;
  font-size: .8rem;
}

.modal-statement {
  margin-top: .5rem !important;
}


/*==============================
 Modal - right container/close & next button
================================*/
section.modal-right-container {
  display: block;
  width: 3rem;
}

.modal-close-button {
  cursor: pointer;
  color: #aaa;
  transition: .2s;
  margin-left: .75rem;
}

#modal-next-button {
  cursor: pointer;
  position: relative;
  top: 33%;
  top: 260px;
  color: #aaa;
  transition: .2s;
  margin-left: .75rem;
  animation-name: nextimage;
  animation-duration: 1s;
}

/************************************************/
/************************************************/
/************************************************/
/************************************************/
/************************************************/
/************************************************/
/************************************************/
/************************************************/


/*=========================
Media Queries - dark mode
==========================*/
@media (prefers-color-scheme: dark) {
  body {
    color: #b3b3b3;
    color: #bfbfbf;
    background-color: #1a1a1a;
  }

  a {
    color: #999;
    color: #b3b3b3;
  }

  ul {
    border-top: 2px solid #999;
    border-bottom: 2px solid #999;
  }

  img {
    opacity: .95;
    transition: opacity .5s ease-in-out;
    border: 1px solid #333;
  }

  .modal-content-container {
    background: #333;
   }

  .modal-info-container p {
    color: #b3b3b3;
    color: #e2e2e9;
  }

  img:hover {
    opacity: 1;
  }
}


/*=========================
Media Queries - small computer
==========================*/
@media (max-width: 1000px) {

  .art-card {
   /* width: 30%;*/
  }

  .modal-background {
    padding-top: 3vh; /* Location of the box */
  }

  .modal-content-container {
    max-width: 700px;
  }

  #modal-back-button {
    top: 220px;
  }

  #modal-next-button {
    top: 179px;
  }

 }


/*=========================
Media Queries - tablets
2 column gallery
==========================*/
@media (max-width: 700px) {

  .art-card {

  }

  .modal-content-container {
    max-width: 80%;
    padding: .25rem;
  }

  #modal-back-button {
    top: 120px;
  }

  #modal-next-button {
    top: 85px;
  }

}

/*=========================
Media Queries - phones
1 column gallery
/*==========================*/
@media (max-width: 415px) {

  #sidebar {
    height: auto;
  }

 .container {
    flex-direction: column;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: .8rem;
  }

  aside {
    width: 100%;
    max-width: 100%;
    margin-right: .5rem;
    margin-bottom: unset;
  }

  #sidebar {
    display: flex;
    flex-direction: row;
    position: unset;
    justify-content: space-between;
    width: 100%;
  }

  ul {
    border-top: 0px solid #ddd;
    padding-top: 0;
    text-align: right;
    margin-block-start: .5em;
  }

  li {
    font-size: 1.1rem;
  }

  .tagline {
    display: none;
  }

  #cv,
  #contact,
  #about {
    font-size: .8rem;
    max-width: 500px;
  }

  .art-card {

    padding: .25rem 0;
    margin-right: unset;
    margin-bottom: .5rem;
  }

  .modal-content-container {
    max-width: 94%;
    padding: .25rem;
  }

  section.modal-left-container {
    width: 6rem;
  }

  #modal-back-button {
    top: 120px;
    margin-right: .25rem;
  }

  #modal-next-button {
    top: 85px;
    margin-left: .25rem;
  }

  svg {
    max-width: 1.75rem;
    max-height: 1.75rem;
  }

  footer {
    display: none;
  }

 }
